import React from "react"
import { Reveal } from "react-awesome-reveal"
import { keyframes } from "@emotion/react"
import { FaHandshake, FaHandPaper, FaSmile } from "react-icons/fa"
import { Zoom } from "react-awesome-reveal"

import Carousel from "../components/Carousel/Carousel"

function Home() {

  const customAnimationFadeLeft = keyframes`
    from {
      opacity: 0;
      transform: translate3d(-40px, 0, 0);
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `

  const customAnimationFadeRight = keyframes`
    from {
      opacity: 0;
      transform: translate3d(40px, 0, 0);;
    }

    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  `

  const protocol = [
    {
      icon: <FaHandshake className="w-1/4 max-w-[20%] h-full" />,
      text: "Certificados y reconocidos por el Ministerio de Salud de Chile"
    },
    {
      icon: <FaHandPaper className="w-1/4 max-w-[20%] h-full" />,
      text: "Mantenemos un estándar de calidad utilizando siempre material quirúrgico esterilizado y materiales desechables"
    },
    {
      icon: <FaSmile className="w-1/4 max-w-[20%] h-full" />,
      text: "Promoción a estilos de vida saludable y logrando la recuperación total de las afecciones del pie."
    }
  ]

  const information = [
    {
      bgUrl: "bg-[url('https://podologia-jireh.s3.sa-east-1.amazonaws.com/bg-podology.jpg')]",
      definitionTitle: "¿Que es la Podología Clínica?",
      definition: "Podología clínica es la ciencia que abarca el diagnóstico y tratamiento de las afecciones de los pie mediante diversas técnicas.",
      utility: [
        "Identificar y tratar dificultades y limitaciones que presenten nuestros pies.",
        "Prevenir enfermedades de los pies.",
        "Mantener e incrementar la salud y bienestar de nuestros pies, dando un mejor caminar día a día."
      ]
    },
    {
      bgUrl: "bg-[url('https://podologia-jireh.s3.sa-east-1.amazonaws.com/bg-depilation.jpg')]",
      definitionTitle: "¿Que es la Depilación con Cera de Abeja?",
      definition: "La depilación con cera de abeja es un método natural y eficaz para eliminar el vello desde la raíz, dejando la piel suave y libre de irritaciones.",
      utility: [
        "Remueve el vello de manera duradera.",
        "Hidrata y nutre la piel de forma natural.",
        "Reducir el crecimiento del vello con el tiempo."
      ]
    },
    {
      bgUrl: "bg-[url('https://podologia-jireh.s3.sa-east-1.amazonaws.com/bg-manicure-pedicure.jpg')]",
      definitionTitle: "¿Que es la Manicure y Pedicure?",
      definition: "La manicure y pedicure son tratamientos de cuidado y embellecimiento para las uñas y la piel de manos y pies, mejorando su salud y apariencia.",
      utility: [
        "Fortalece y protege las uñas.",
        "Hidrata y suaviza la piel.",
        "Previene problemas como uñas encarnadas y resequedad.",
        "Realza la belleza con un toque estético y saludable."
      ]
    }
  ]

  return (
    <div>
      <Carousel />
      <Zoom>
        <div className="w-full md:w-full h-full grid grid-flow-row md:grid-cols-2 lg:md:grid-cols-3 px-10 py-10 gap-5 text-lg font-medium">
          {protocol.map((protocol, indexProtocol) => (  
            <div key={indexProtocol} className="flex items-center gap-5 border-2 rounded-3xl p-5 shadow-xl">
              {protocol.icon}
              <span className="w-3/4">{protocol.text}</span>
            </div>
          ))}
        </div>
      </Zoom>

      <div>
        {information.map((information, indexInformation) => (
          <div key={indexInformation} className={`w-full h-full  bg-fixed bg-cover ${information.bgUrl}`}>
            <div className="py-10 px-10 bg-gray-50/40">
              <Reveal keyframes={customAnimationFadeLeft}>
                <div className="flex flex-col py-5 items-start">
                  <div className="flex flex-col md:w-2/4">
                    <p className="font-extrabold text-3xl text-white drop-shadow-[0_1.2px_1.2px_rgba(1,1,1,1)]">{information.definitionTitle}</p>
                    <hr className="w-40 md:w-60 h-1 bg-sky-600 my-2 rounded-full" />
                    <p className="font-semibold text-lg">{information.definition}</p>
                  </div>
                </div>
              </Reveal>

              <Reveal keyframes={customAnimationFadeRight}>
                <div className="flex flex-col py-5 items-end">
                  <div className="flex flex-col md:w-2/4 md:justify-items-end">
                    <p className="font-extrabold text-3xl text-white drop-shadow-[0_1.2px_1.2px_rgba(1,1,1,1)]">¿Para que nos sirve?</p>
                    <hr className="w-40 md:w-60 h-1 bg-sky-600 my-2 rounded-full" />
                    <ul className="list-disc font-semibold text-lg px-5">
                      {information.utility.map((utility, indexUtility) => (
                        <li key={indexUtility}>{utility}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </Reveal>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Home
